<template>
    <div>
        <view-layout v-if="question">
            <template #header>
                <page-header>
                    <template #title>
                        {{ question.title }}
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-row>
                    <v-col cols="12" md="8">
                        <table-card class="mb-3 pa-5">
                            <template #content>
                                <communityquestion-questioncard :question="question" @mark-as-solved="markAsSolved" />
                            </template>
                        </table-card>

                        <h2 class="mt-6 mb-1 ml-2">Antwoorden</h2>
                        <table-card v-for="answer in sortedAnswers" id="replies" :key="answer.id" class="mb-5 pa-5">
                            <template #content>
                                <communityquestion-answer :answer="answer" :question="question" />
                            </template>
                        </table-card>

                        <table-card v-if="canAnswer" class="pa-5">
                            <template #content>
                                <communityquestion-reply @reply="createAnswer" />
                            </template>
                        </table-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <table-card class="pa-5">
                            <template #content>
                                <communityquestion-rules />
                            </template>
                        </table-card>
                    </v-col> </v-row
            ></template>
        </view-layout>

        <loader v-if="loading" />

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />
    </div>
</template>

<script>
// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader.vue';
import PageHeader from '@/components/PageHeader.vue';
import TableCard from '@/components/TableCard.vue';
import CommunityquestionQuestioncard from './CommunityquestionQuestioncard.vue';
import CommunityquestionAnswer from './CommunityquestionAnswer.vue';
import CommunityquestionReply from './CommunityquestionReply.vue';
import CommunityquestionRules from './CommunityquestionRules.vue';
import { createCommunityanswer, getCommunityquestion, markQuestionAsSolved } from '@/services/communityquestion';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';

export default {
    data() {
        return {
            loading: false,
            question: null,
            dialog: false,
            dialogAttributes: {
                attributes: {},
                actions: {}
            }
        };
    },

    async created() {
        await this.fetchData();
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        sortedAnswers() {
            return [...this.question.answers].sort((a, b) => {
                return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1;
            });
        },
        isApproved() {
            if (!this.question) {
                return false;
            }
            return this.question.approved;
        },
        canAnswer() {
            return !this.question.solved && this.isApproved;
        }
    },

    methods: {
        async fetchData() {
            if (this.$route.params.id) {
                try {
                    this.loading = true;
                    this.question = await getCommunityquestion(this.$route.params.id);
                } catch (e) {
                    apiErrorAndDisplay.call(this, e);
                    this.$router.push({
                        name: 'communityQuestions'
                    });
                } finally {
                    this.loading = false;
                }
            }
        },
        async markAsSolved() {
            try {
                this.loading = true;
                await markQuestionAsSolved(this.question.id);

                notify.call(this, {
                    title: this.$t('addCommunityquestion.marked_as_solved'),
                    text: this.$t('addCommunityquestion.marked_as_solved')
                });

                await this.fetchData();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async createAnswer(answer) {
            try {
                this.loading = true;
                await createCommunityanswer(this.question.id, {
                    text: answer.content,
                    adminCredentials: answer.adminCedentials
                });

                notify.call(this, {
                    title: this.$t('addAnswer.succesfuly_created'),
                    text: this.$t('addAnswer.succesfuly_created')
                });

                await this.fetchData();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    },

    components: {
        ViewLayout,
        PageHeader,
        Loader,
        TableCard,
        CommunityquestionQuestioncard,
        CommunityquestionAnswer,
        CommunityquestionReply,
        CommunityquestionRules,
        ConfirmationDialog
    }
};
</script>

<style lang="scss" scoped></style>
