import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { CommunityAnswer } from '@/services/communityanswer.types';

const BASE_URL = generateEndpoint('/api/communityanswers');

export function editCommunityanswer(answerId: number, answer: { text: string }): Promise<CommunityAnswer> {
    return axios.put(`${BASE_URL}/${answerId}`, { answer }).then((response) => response.data);
}

export function getCommunityanswer(id: number): Promise<CommunityAnswer> {
    return axios.get(`${BASE_URL}/${id}`).then((response) => response.data);
}

export function deleteAnswer(answerId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${answerId}`).then((response) => response.data);
}
