<template>
    <div>
        <view-layout>
            <template #content>
                <v-row>
                    <v-col cols="12" md="8">
                        <table-card class="mb-3 pa-5">
                            <template #content>
                                <h2>Stel een vraag</h2>
                                <v-divider class="mb-3 mt-1" />

                                <v-form v-model="valid">
                                    <v-row>
                                        <v-col cols="12">
                                            <text-field
                                                v-model="question.title"
                                                data-form="title"
                                                :disabled="!isCreatingNew"
                                                label="Titel"
                                                required
                                                :rules="[...requiredRules, ...minRules(5)]"
                                            />
                                        </v-col>
                                        <v-col cols="12" data-select-container="category">
                                            <v-select
                                                v-model="question.categoryId"
                                                label="Categorie *"
                                                :items="categories"
                                                item-value="id"
                                                item-text="name"
                                                outlined
                                                hide-details="auto"
                                                dense
                                                persistent-hint
                                                required
                                                :rules="requiredRules"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <editor
                                                v-model="question.text"
                                                data-form="description"
                                                type="card"
                                                placeholder="Stel je vraag"
                                                :rules="[...requiredRules, ...minRules(20)]"
                                            >
                                            </editor>
                                        </v-col>
                                        <v-col cols="12">
                                            <submit-button
                                                :disabled="!isValid"
                                                data-action="create"
                                                @click="createQuestion"
                                                >{{
                                                    isCreatingNew
                                                        ? $t('addCommunityquestion.ask_question')
                                                        : $t('addCommunityquestion.update_question')
                                                }}</submit-button
                                            >
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </template>
                        </table-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <table-card class="pa-5">
                            <template #content>
                                <communityquestion-rules />
                            </template>
                        </table-card>
                    </v-col> </v-row
            ></template>
        </view-layout>

        <loader v-if="loading" />
    </div>
</template>

<script>
// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import TableCard from '@/components/TableCard.vue';
import Editor from '@/components/Editor/DefaultEditor.vue';
import CommunityquestionRules from './CommunityquestionRules.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { getCommunitycategories } from '@/services/communitycategories';

import { createCommunityquestions, getCommunityquestion, updateCommunityquestions } from '@/services/communityquestion';
import { isAdmin } from '@/helpers/auth';
import { mapState } from 'vuex';

export default {
    mixins: [formRulesMixin],

    data() {
        return {
            loading: false,
            question: {
                title: null,
                text: '',
                categoryId: null
            },
            valid: null,
            communitycategories: []
        };
    },

    async created() {
        try {
            await getCommunitycategories().then((categories) => {
                this.communitycategories = categories;
            });

            if (this.$route.params.id) {
                this.loading = true;
                this.question = await getCommunityquestion(this.$route.params.id);

                this.hasAccessToResource(this.question);
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            this.$router.push({
                name: 'communityQuestions'
            });
        } finally {
            this.loading = false;
        }
    },

    methods: {
        hasAccessToResource(question) {
            if (isAdmin(this.currentUserData)) {
                return true;
            }

            return question.userId === this.currentUserData.id;
        },
        async createQuestion() {
            this.loading = true;
            try {
                let question;
                if (this.question.id) {
                    question = await updateCommunityquestions(this.question.id, this.question);

                    notify.call(this, {
                        title: this.$t('addCommunityquestion.updated_succesfuly.title'),
                        text: this.$t('addCommunityquestion.updated_succesfuly.text')
                    });
                } else {
                    question = await createCommunityquestions(this.question);

                    notify.call(this, {
                        title: this.$t('addCommunityquestion.created_succesfuly.title'),
                        text: this.$t('addCommunityquestion.created_succesfuly.text')
                    });
                }

                this.$router.push({
                    name: 'communityquestion',
                    params: {
                        id: question.id
                    }
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        isValid() {
            return Boolean(this.valid && this.question.text.length >= 20);
        },
        isCreatingNew() {
            return !this.question.id;
        },
        categories() {
            return this.communitycategories.filter((_category) => {
                return _category.language === this.currentUserData.userinformation.language;
            });
        }
    },

    components: {
        ViewLayout,
        Loader,
        TableCard,
        CommunityquestionRules,
        Editor,
        SubmitButton
    }
};
</script>

<style lang="scss" scoped></style>
