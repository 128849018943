<template>
    <div>
        <table-card v-for="question in questions" :key="question.id" class="px-5 py-2 mb-3">
            <template #content>
                <communityquestions-questionslist-item class="item" :question="question" />
            </template>
        </table-card>
    </div>
</template>

<script>
import CommunityquestionsQuestionslistItem from './CommunityquestionsQuestionslistItem.vue';
import TableCard from '@/components/TableCard.vue';

export default {
    components: {
        CommunityquestionsQuestionslistItem,
        TableCard
    },
    props: {
        questions: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            loading: false
        };
    }
};
</script>
