<template>
    <div>
        <view-layout>
            <template #content>
                <v-row>
                    <v-col cols="12" md="8">
                        <table-card class="mb-3 pa-5">
                            <template #content>
                                <h2>Update je vraag</h2>
                                <v-divider class="mb-3 mt-1" />

                                <v-form v-model="valid">
                                    <v-row>
                                        <v-col cols="12">
                                            <editor
                                                v-model="answer.text"
                                                type="card"
                                                placeholder="Bewerk je antwoord"
                                                :rules="[...requiredRules]"
                                            >
                                            </editor>
                                        </v-col>
                                        <v-col cols="12">
                                            <submit-button :disabled="!isValid" @click="updateAnswer">{{
                                                $t('addCommunityquestion.update_communityanswer')
                                            }}</submit-button>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </template>
                        </table-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <table-card class="pa-5">
                            <template #content>
                                <communityquestion-rules />
                            </template>
                        </table-card>
                    </v-col> </v-row
            ></template>
        </view-layout>

        <loader v-if="loading" />
    </div>
</template>

<script>
// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import TableCard from '@/components/TableCard.vue';
import Editor from '@/components/Editor/DefaultEditor.vue';
import CommunityquestionRules from './CommunityquestionRules.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import { createCommunityquestions } from '@/services/communityquestion';
import { getCommunityanswer, editCommunityanswer } from '@/services/communityanswer';
import { isAdmin } from '@/helpers/auth';
import { mapState } from 'vuex';

export default {
    mixins: [formRulesMixin],

    data() {
        return {
            loading: false,
            answer: {
                text: ''
            },
            valid: null
        };
    },

    async created() {
        if (this.$route.params.id) {
            try {
                this.loading = true;
                this.answer = await getCommunityanswer(this.$route.params.id);

                this.hasAccessToResource(this.answer);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    },

    methods: {
        hasAccessToResource(answer) {
            if (isAdmin(this.currentUserData)) {
                return true;
            }

            return answer.userId === this.currentUserData.id;
        },
        async updateAnswer() {
            this.loading = true;
            try {
                let answer;
                if (this.answer.id) {
                    answer = await editCommunityanswer(this.answer.id, this.answer);

                    notify.call(this, {
                        title: this.$t('addCommunityquestion.updated_succesfuly.title'),
                        text: this.$t('addCommunityquestion.updated_succesfuly.text')
                    });
                } else {
                    throw new Error(`??`);
                    // answer = await createCommunityquestions(this.answer);

                    // notify.call(this, {
                    //     title: this.$t('addCommunityquestion.created_succesfuly.title'),
                    //     text: this.$t('addCommunityquestion.created_succesfuly.text')
                    // });
                }

                this.$router.push({
                    name: 'communityquestion',
                    params: {
                        id: answer.questionId
                    }
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        isValid() {
            return Boolean(this.valid && this.answer.text.length >= 5);
        }
    },

    components: {
        ViewLayout,
        Loader,
        TableCard,
        CommunityquestionRules,
        Editor,
        SubmitButton
    }
};
</script>

<style lang="scss" scoped></style>
