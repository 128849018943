<template>
    <div v-if="question" class="grid-container">
        <div class="content">
            <div class="title-bar">
                <div class="title">
                    <router-link :to="{ name: 'communityquestion', params: { id: question.id } }">{{
                        question.title
                    }}</router-link>
                </div>
                <div>{{ question.answers.length }} <v-icon>mdi-message-reply</v-icon></div>
            </div>
            <div class="bottom-bar">
                <div class="d-flex">
                    <div class="category">
                        <chip>{{ category }}</chip>
                    </div>
                    <div v-if="question.solved" class="solved"><chip color="green">Opgelost</chip></div>
                    <div v-if="!question.approved" class="solved"><chip color="red">Keuring nodig</chip></div>
                </div>
                <div class="timestamp">Gepost op {{ dateFormatted }} door {{ question.creatorInitials }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import Chip from '@/components/Chip.vue';
export default {
    components: {
        Chip
    },

    props: {
        question: {}
    },
    computed: {
        dateFormatted() {
            return dayjs(this.question.createdAt).format('DD-MM-YYYY HH:mm:ss');
        },
        category() {
            return this.question.category.name;
        }
    }
};
</script>

<style lang="scss" scoped>
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px 0px;
    grid-template-areas: '. .';

    .left-column {
        padding-left: 10px;
        padding-right: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .title-bar {
        display: flex;
        justify-content: space-between;
    }

    .bottom-bar {
        display: flex;
        justify-content: space-between;
    }

    .solved-icon {
        margin-top: 10px;
    }

    .solved-icon .icon {
        color: green;
        font-size: 21px;
    }

    .votes {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .timestamp {
        color: $color-text-grey;
        font-size: 0.75em;
    }
}

.title {
    margin-bottom: 10px;

    a {
        text-decoration: none !important;
    }
}

.avatar {
    color: white;
}
</style>
