<template>
    <div>
        <view-layout>
            <template #content>
                <v-row>
                    <v-col v-if="!loading" cols="12" md="8">
                        <communityquestions-filterbar class="mb-5" @apply-filter="applyFilter" />
                        <communityquestions-questionslist :questions="questions" />
                        <infinite-loading :identifier="identifier" @infinite="infiniteHandler">
                            <template #no-results>Geen vragen gevonden</template>
                            <template #no-more>Alle vragen zijn geladen</template>
                        </infinite-loading>
                    </v-col>
                    <v-col cols="12" md="4">
                        <table-card class="pa-5">
                            <template #content>
                                <communityquestion-rules />
                            </template>
                        </table-card>
                    </v-col>
                </v-row>
            </template>
        </view-layout>

        <loader v-if="loading" />
    </div>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader.vue';
import TableCard from '@/components/TableCard.vue';
import CommunityquestionsQuestionslist from './CommunityquestionsQuestionslist.vue';
import CommunityquestionsFilterbar from './CommunityquestionsFilterbar.vue';
import CommunityquestionRules from './CommunityquestionRules.vue';
import { listCommunityquestions } from '@/services/communityquestion';
import InfiniteLoading from 'vue-infinite-loading';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            loading: false,
            page: 1,
            questions: [],
            filterOptions: {},
            identifier: +new Date()
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        selectedLanguage() {
            return this.currentUserData.userinformation.language;
        }
    },

    methods: {
        infiniteHandler($state) {
            listCommunityquestions(this.page, {
                ...this.filterOptions,
                language: this.selectedLanguage
            }).then((data) => {
                if (data.length) {
                    this.page += 1;
                    this.questions.push(...data);
                    $state.loaded();
                } else {
                    $state.complete();
                }
            });
        },
        applyFilter(filtering) {
            this.loading = true;
            this.identifier = +new Date();
            this.page = 1;
            this.questions = [];
            this.filterOptions = filtering;
            this.loading = false;
        }
    },

    components: {
        ViewLayout,
        Loader,
        TableCard,
        CommunityquestionsQuestionslist,
        CommunityquestionsFilterbar,
        CommunityquestionRules,
        InfiniteLoading
    }
};
</script>

<style lang="scss" scoped></style>
