
import Editor from '@/components/Editor/DefaultEditor.vue';
import Vue from 'vue';
import { mapState } from 'vuex';
import TextField from '@/components/FormComponents/TextField.vue';
import { isAdmin } from '@/helpers/auth';
import { MapStateToComputed } from '@/helpers/types';
import { InitializedGeneralAuthState } from '@/store/modules/auth';
export default Vue.extend({
    components: { Editor, TextField },

    data() {
        return {
            loading: false,
            // starting editor's content
            content: ``,
            adminCedentials: undefined as undefined | string
        };
    },

    computed: {
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedGeneralAuthState>),
        canSetCustomCredentials(): boolean {
            return false;
            // return isAdmin(this.currentUserData);
        }
    },

    methods: {
        submit(): void {
            this.$emit('reply', {
                content: this.content,
                adminCedentials: this.adminCedentials
            });
            this.content = '';
            this.adminCedentials = undefined;
        }
    }
});
