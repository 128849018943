<template>
    <div>
        <table-card>
            <template #content>
                <form class="filter-bar" @submit.prevent="applyFilter">
                    <v-select
                        v-model="filtering.categoryId"
                        class="mr-2"
                        label="Categorie"
                        :items="categories"
                        item-value="id"
                        item-text="name"
                        outlined
                        hide-details="auto"
                        dense
                        persistent-hint
                        clearable
                        required
                    />
                    <pagination-search v-model="filtering.search" class="mr-2" />
                    <submit-button type="submit" @click="applyFilter">
                        {{ $t('communityquestions.filter') }}
                    </submit-button>
                    <submit-button class="ml-2" @click="createQuestion">
                        {{ $t('communityquestions.ask_question') }}
                    </submit-button>
                </form>
            </template>
        </table-card>
    </div>
</template>

<script>
import TableCard from '@/components/TableCard.vue';
import PaginationSearch from '@/components/PaginationSearch.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import { getCommunitycategories } from '@/services/communitycategories';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            loading: false,
            items: [
                {
                    label: 'Openstaand',
                    value: 'open'
                },
                {
                    label: 'Opgelost',
                    value: 'closed'
                }
            ],
            filtering: {
                categoryId: null,
                search: '',
                status: null
            },
            communitycategories: []
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        categories() {
            return this.communitycategories.filter((_category) => {
                return _category.language === this.currentUserData.userinformation.language;
            });
        }
    },

    created() {
        this.loading = true;
        getCommunitycategories()
            .then((categories) => {
                this.communitycategories = categories;
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        createQuestion() {
            this.$router.push({
                name: 'createCommunityQuestion'
            });
        },
        applyFilter() {
            this.$emit('apply-filter', {
                search: this.filtering.search,
                status: this.filtering.status,
                categoryId: this.filtering.categoryId
            });
        }
    },

    components: {
        TableCard,
        PaginationSearch,
        SubmitButton
    }
};
</script>

<style lang="scss" scoped>
.item {
    margin-bottom: 20px;
}

.filter-bar {
    display: flex;
    align-items: center;
}
</style>
