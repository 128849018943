import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { generateEndpoint } from '@/services/config';
import { filteringOptions, PaginationResponse } from '@/common/types';
import {
    Communityquestion,
    ICreateCommunityquestionData,
    IUpdateCommunityquestionData
} from '@/services/communityquestion.types';
import { CommunityAnswer } from '@/services/communityanswer.types';

const BASE_URL = generateEndpoint('/api/communityquestions');

export function getCommunityquestion(id: number): Promise<Communityquestion> {
    return axios.get(`${BASE_URL}/${id}`).then((response) => response.data);
}

export function paginationAllCommunityquestions(
    options: filteringOptions
): Promise<PaginationResponse<Communityquestion>> {
    return axios
        .get(`${BASE_URL}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function listCommunityquestions(page: number, filterOptions = {}): Promise<Communityquestion[]> {
    return axios
        .get(`${BASE_URL}/list`, {
            params: {
                page,
                ...filterOptions
            }
        })
        .then((response) => response.data);
}

export function listOwnCommunityquestions(userId: number, page: number): Promise<Communityquestion[]> {
    return axios
        .get(`${BASE_URL}/list/own`, {
            params: {
                page
            }
        })
        .then((response) => response.data);
}

export function createCommunityquestions(question: ICreateCommunityquestionData): Promise<{ coreQuestionId: string }> {
    return axios.post(`${BASE_URL}`, { question }).then((response) => response.data);
}

export function updateCommunityquestions(
    questionId: number,
    question: IUpdateCommunityquestionData
): Promise<Communityquestion> {
    return axios.put(`${BASE_URL}/${questionId}`, { question }).then((response) => response.data);
}

export function markQuestionAsSolved(questionId: number): Promise<Communityquestion> {
    return axios.put(`${BASE_URL}/${questionId}/solved`).then((response) => response.data);
}

export function createCommunityanswer(
    questionId: number,
    answer: { text: string; adminCredentials?: string }
): Promise<CommunityAnswer> {
    return axios.post(`${BASE_URL}/${questionId}/answer`, { answer }).then((response) => response.data);
}

export function approveQuestion(questionId: number): Promise<Communityquestion> {
    return axios.put(`${BASE_URL}/${questionId}/approve`).then((response) => response.data);
}

export function denyQuestion(questionId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${questionId}`).then((response) => response.data);
}

export function deleteQuestion(questionId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${questionId}`).then((response) => response.data);
}
