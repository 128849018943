<template>
    <div>
        <view-layout>
            <template #content>
                <v-row>
                    <v-col cols="12" md="8">
                        <communityquestions-questionslist :questions="questions" />
                        <infinite-loading @infinite="infiniteHandler">
                            <template #no-results>Geen vragen gevonden</template>
                        </infinite-loading>
                    </v-col>
                    <v-col cols="12" md="4">
                        <table-card class="pa-5">
                            <template #content>
                                <communityquestion-rules />
                            </template>
                        </table-card>
                    </v-col>
                </v-row>
            </template>
        </view-layout>

        <loader v-if="loading" />
    </div>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader.vue';
import TableCard from '@/components/TableCard.vue';
import CommunityquestionsQuestionslist from './CommunityquestionsQuestionslist.vue';
import CommunityquestionRules from './CommunityquestionRules.vue';
import { listOwnCommunityquestions } from '@/services/communityquestion';
import InfiniteLoading from 'vue-infinite-loading';

export default {
    components: {
        ViewLayout,
        Loader,
        TableCard,
        CommunityquestionsQuestionslist,
        CommunityquestionRules,
        InfiniteLoading
    },
    data() {
        return {
            loading: false,
            page: 1,
            questions: []
        };
    },

    methods: {
        infiniteHandler($state) {
            listOwnCommunityquestions(this.$route.params.id, this.page).then((data) => {
                if (data.length) {
                    this.page += 1;
                    this.questions.push(...data);
                    $state.loaded();
                } else {
                    $state.complete();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
