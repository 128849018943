<template>
    <div>
        <communityquestion-card>
            <template #left-sidebar>
                <v-avatar class="avatar" size="38" color="dexDark">
                    {{ question.creatorInitials }}
                </v-avatar>

                <div v-if="question.solved" class="solved-icon">
                    <v-icon class="icon">mdi-checkbox-marked-circle</v-icon>
                </div>

                <div class="votes"></div>
            </template>
            <template #content>
                <div class="timestamp">Gepost op {{ dateFormatted }}</div>
                <div class="title">
                    {{ question.title }}
                </div>
                <div class="text" v-html="question.text"></div>
            </template>

            <template #bottom-bar>
                <div class="d-flex justify-space-between" style="flex: auto">
                    <div class="d-flex">
                        <div class="category">
                            <chip>{{ category }}</chip>
                        </div>
                        <div v-if="question.solved" class="solved"><chip color="green">Opgelost</chip></div>
                        <div v-if="false" class="report"></div>

                        <div v-if="canEditQuestion" class="edit">
                            <button @click="editQuestion"><v-icon>mdi-pencil</v-icon> bewerken</button>
                        </div>
                        <div v-if="canMarkAsSolved" class="edit">
                            <button @click="markAsSolvedQuestion">
                                <v-icon>mdi-checkbox-marked-circle</v-icon> markeren als opgelost
                            </button>
                        </div>
                        <div v-if="isAdminUser" class="edit"></div>
                    </div>
                </div>
            </template>
        </communityquestion-card>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import Chip from '@/components/Chip.vue';
import CommunityquestionCard from './CommunityquestionCard.vue';
import { mapState } from 'vuex';
import { isAdmin } from '@/helpers/auth';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';

export default {
    props: {
        question: {}
    },

    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        category() {
            return this.question.category.name;
        },
        dateFormatted() {
            return dayjs(this.question.createdAt).format('DD-MM-YYYY HH:mm:ss');
        },
        isOwnQuestion() {
            return this.question.userId === this.currentUserData.id;
        },
        isAdminUser() {
            return isAdmin(this.currentUserData);
        },
        canMarkAsSolved() {
            return !this.question.solved && this.question.approved && this.userCanMarkAsSolved;
        },
        userCanMarkAsSolved() {
            return this.isOwnQuestion || this.isAdminUser;
        },
        userCanEditQuestion() {
            return this.isOwnQuestion || this.isAdminUser;
        },
        canEditQuestion() {
            return !this.question.solved && this.userCanEditQuestion;
        }
    },

    methods: {
        markAsSolvedQuestion() {
            this.$emit('mark-as-solved');
        },
        editQuestion() {
            this.$router.push({
                name: 'update-communityquestion',
                params: {
                    id: this.question.id
                }
            });
        }
    },

    components: {
        Chip,
        CommunityquestionCard
    }
};
</script>

<style lang="scss" scoped>
.bottom-bar {
    display: flex;
    margin-top: 20px;

    .comments,
    .solved,
    .report,
    .edit,
    .category {
        margin-right: 10px;
    }
}

.solved-icon {
    margin-top: 10px;
}

.solved-icon .icon {
    color: green;
    font-size: 21px;
}

.votes {
    margin-top: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.timestamp {
    color: $color-text-grey;
    font-size: 0.75em;
}

.title {
    margin-bottom: 10px;

    a {
        text-decoration: none !important;
    }
}

.avatar {
    color: white;
}
</style>
