<template>
    <div class="grid-container">
        <div class="left-column">
            <v-avatar class="avatar" size="38" color="dexDark">
                {{ answer.creatorInitials }}
            </v-avatar>

            <div class="votes"></div>
        </div>
        <div class="content">
            <div>
                <div class="timestamp">Gepost op {{ dateFormatted }}</div>
                <div class="text" v-html="answer.text"></div>
            </div>
            <div v-if="canEditAnswer">
                <button @click="editAnswer"><v-icon>mdi-pencil</v-icon> bewerken</button>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { isAdmin } from '@/helpers/auth';
// --- Components ---

// --- State ---

// --- Error handling ---

export default {
    props: {
        answer: {},
        question: {}
    },

    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        dateFormatted() {
            return dayjs(this.answer.createdAt).format('DD-MM-YYYY HH:mm:ss');
        },
        isOwnAnswer() {
            return this.answer.userId === this.currentUserData.id;
        },
        canEditAnswer() {
            return this.isOwnAnswer && this.question.solved === false;
        },
        isAdminUser() {
            return isAdmin(this.currentUserData);
        }
    },

    methods: {
        editAnswer() {
            this.$router.push({
                name: 'update-communityanswer',
                params: {
                    id: this.answer.id
                }
            });
        }
    },

    components: {}
};
</script>

<style lang="scss" scoped>
.grid-container {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: auto 3fr;
    gap: 0px 0px;
    grid-template-areas: '. .';

    .left-column {
        padding-left: 10px;
        padding-right: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .bottom-bar {
        display: flex;
        margin-top: 20px;

        .comments,
        .solved,
        .edit,
        .category {
            margin-right: 10px;
        }
    }

    .solved-icon {
        margin-top: 10px;
    }

    .solved-icon .icon {
        color: green;
        font-size: 21px;
    }

    .votes {
        margin-top: 20px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .timestamp {
        color: $color-text-grey;
        font-size: 0.75em;
    }
}

.title {
    margin-bottom: 10px;

    a {
        text-decoration: none !important;
    }
}

.avatar {
    color: white;
}
</style>
